<ng-content select="[left-side]" />
<form (ngSubmit)="submit(formRef)" #formRef="ngForm" [formGroup]="form" autocomplete="off" class="form">
	<h2 class="form__header">Расскажите о&nbsp;своём проекте</h2>

	<section class="form__fields">
		<tk-form-field>
			<tk-label>Как нам лучше к вам обращаться?</tk-label>
			<input
				[imask]="textMask"
				formControlName="name"
				placeholder="Дмитрий Иванов"
				tkInput
				type="text"
				(input)="onInput($event, 'name')"
			/>
			<tk-form-field-info>
				<tk-form-field-error *ngIf="formRef.submitted" [errors]="form.get('name')?.errors" />
				<tk-form-field-text-counter
					[currentCount]="form.get('name')?.value?.length || 0"
					[maxCount]="nameMaxLength"
				/>
			</tk-form-field-info>
		</tk-form-field>

		<tk-form-field>
			<tk-label>Из какой вы компании?</tk-label>
			<input
				formControlName="company"
				tkInput
				type="text"
				placeholder="Яндекс"
				(input)="onInput($event, 'company')"
			/>
			<tk-form-field-info>
				<tk-form-field-error *ngIf="formRef.submitted" [errors]="form.get('company')?.errors" />
				<tk-form-field-text-counter
					[currentCount]="form.get('company')?.value?.length || 0"
					[maxCount]="companyMaxLength"
				/>
			</tk-form-field-info>
		</tk-form-field>

		<tk-form-field>
			<tk-label>Ваши контактные данные</tk-label>
			<input
				[imask]="phoneMask"
				[unmask]="true"
				formControlName="contact"
				placeholder="+7 999 123-45-66"
				tkInput
				type="text"
			/>
			<tk-form-field-info>
				<tk-form-field-error *ngIf="formRef.submitted" [errors]="form.get('contact')?.errors" />
			</tk-form-field-info>
		</tk-form-field>

		<div class="form__sub-fields">
			<h3>Бюджет проекта</h3>
			<tk-tags-control formControlName="budget" [isMultiple]="false" [options]="budgets" />
		</div>

		<div class="form__sub-fields">
			<h3>Опишите задачу</h3>
			<tk-form-field>
				<textarea
					formControlName="task_description"
					placeholder="Мне нужен сервис, чтобы приносил деньги пассивно"
					tkInput
					type="text"
					(input)="onInput($event, 'task_description')"
				>
				</textarea>
				<tk-form-field-info>
					<tk-form-field-error *ngIf="formRef.submitted" [errors]="form.get('task_description')?.errors" />
					<tk-form-field-text-counter
						[currentCount]="form.get('task_description')?.value?.length || 0"
						[maxCount]="taskDescriptionMaxLength"
					/>
				</tk-form-field-info>
			</tk-form-field>
		</div>
		<tk-agreement />
		<tk-button-group class="tk-button-group">
			<button [attr.disabled]="(loading$ | async) || null" type="submit">Отправить</button>
		</tk-button-group>
	</section>
</form>
