import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormBuilder, FormGroupDirective, ReactiveFormsModule, Validators } from '@angular/forms';
import { LoadingService } from '@services/loading.service';
import { Budget } from '@models/enums/budget.enum';
import { Dialog } from '@angular/cdk/dialog';
import { ToastService } from '@services/toast.service';
import { TkFormFieldModule } from '@technokratos/components-angular/form-field';
import { TkRadioModule } from '@technokratos/components-angular/radio';
import { TkButtonModule } from '@technokratos/components-angular/button';
import { TkInputModule } from '@technokratos/components-angular/input';
import { AsyncPipe, CommonModule } from '@angular/common';
import { FeedbackFormsService } from '@technokratos-public/services/feedback-forms.service';
import { phoneMask, textMask } from '@utils/masks';
import { IMaskDirective } from 'angular-imask';
import { AgreementComponent } from '@technokratos-public/components/ui/agreement/agreement.component';
import { FormFieldErrorComponent } from '@ui/form-field-error/form-field-error.component';
import { FormFieldTextCounterComponent } from '@ui/form-field-text-counter/form-field-text-counter.component';
import { FormFieldInfoComponent } from '@ui/form-field-info/form-field-info.component';
import { TagsControlComponent } from '@ui/tags-control/tags-control.component';

@Component({
	standalone: true,
	selector: 'tk-project',
	templateUrl: './project.component.html',
	styleUrls: ['./project.component.sass'],
	providers: [FeedbackFormsService, LoadingService],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		CommonModule,
		ReactiveFormsModule,
		TkFormFieldModule,
		TkRadioModule,
		TkButtonModule,
		TkInputModule,
		AsyncPipe,
		IMaskDirective,
		AgreementComponent,
		FormFieldErrorComponent,
		FormFieldTextCounterComponent,
		FormFieldInfoComponent,
		TagsControlComponent,
	],
})
export class ProjectComponent {
	readonly loading$ = inject(LoadingService);
	protected readonly phoneMask = phoneMask;
	protected readonly textMask = textMask;
	private fb = inject(FormBuilder);
	private service = inject(FeedbackFormsService);
	private toast = inject(ToastService);
	private dialog = inject(Dialog);
	private selectedBudget: Budget | null = null;
	nameMaxLength = 80;
	companyMaxLength = 100;
	taskDescriptionMaxLength = 2000;
	form = this.fb.nonNullable.group({
		name: ['', [Validators.required, Validators.maxLength(this.nameMaxLength)]],
		company: ['', [Validators.required, Validators.maxLength(this.companyMaxLength)]],
		contact: ['', [Validators.required, Validators.minLength(12)]],
		budget: this.fb.control<null | Budget>(null),
		task_description: ['', [Validators.required, Validators.maxLength(this.taskDescriptionMaxLength)]],
	});

	budgets = [
		{
			value: Budget.LOW,
			label: '5–10 млн',
		},
		{
			value: Budget.MIDDLE,
			label: '10–20 млн',
		},
		{
			value: Budget.HIGH,
			label: 'от 20 млн',
		},
	];

	constructor() {
		this.loading$.next(false);
	}

	onInput(event: Event, field: string) {
		this.form.get(field)?.setValue((event.target as HTMLInputElement).value);
	}

	skipBudgetSelection(value: Budget) {
		if (this.form.controls.budget.value === this.selectedBudget) {
			this.form.controls.budget.setValue(null);
			this.selectedBudget = null;
		} else {
			this.selectedBudget = value;
		}
	}

	submit(formRef: FormGroupDirective) {
		if (this.form.invalid) {
			return;
		}

		this.loading$.next(true);
		this.service.createLeadRequest(this.form.getRawValue()).subscribe({
			next: () => {
				formRef.resetForm();
				this.toast.success('Успешно отправлено');
			},
			error: () => this.toast.error('Ошибка'),
			complete: () => {
				this.loading$.next(false);
				this.dialog.closeAll();
			},
		});
	}
}
